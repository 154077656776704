import { Component } from '@angular/core';

@Component({
  selector: 'app-issues-status-info',
  standalone: false,

  templateUrl: './issues-status-info.component.html',
  styleUrl: './issues-status-info.component.scss',
})
export class IssuesStatusInfoComponent {}
