<error-page-content>
  <h1 class="error-code">403</h1>
  <h2 class="error-main-text">Sorry, you don't have access to this page.</h2>
  <p class="pb-2">Please try our Home Page for the Portal or our Corporate Site:</p>
  <div class="row">
    <div class="col-6">
      <a mat-stroked-button color="primary" class="error-nav-button" [href]="main_site_url">Corporate Site</a>
    </div>
    <div class="col-6">
      <a mat-flat-button color="primary" class="error-nav-button" href="/">Home Page</a>
    </div>
  </div>
</error-page-content>
