@if (data(); as data) {
  <mat-card [attr.data-test-id]="data.title">
    <mat-card-header>
      <h2>Status of {{ data.title }}</h2>
    </mat-card-header>
    <mat-card-content>
      <div class="table-content">
        <table mat-table matSort [dataSource]="data.dataSource" class="mat-elevation-z8">
          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef class="header-border-right">Name</th>
            <td mat-cell *matCellDef="let element" class="table-elem-border-right">
              <div class="d-flex align-items-center g-2">
                @if (isConnectors()) {
                  <div class="smaller-icon pe-2">
                    @if (element.connector_type === 'BUSINESS_CRITICAL') {
                      <mat-icon
                        svgIcon="business-critical-connector"
                        [matTooltip]="'Business Critical connector'"
                        matTooltipClass="custom-tooltip-transform-x"
                      ></mat-icon>
                    } @else if (element.connector_type === 'STANDARD') {
                      <mat-icon
                        svgIcon="standard-connector"
                        [matTooltip]="'Standard connector'"
                        matTooltipClass="custom-tooltip-transform-x"
                      ></mat-icon>
                    }
                  </div>
                }
                {{ element.name }}
              </div>
            </td>
          </ng-container>

          <!-- Pipeline Name Column -->
          <ng-container matColumnDef="pipeline_name">
            <th mat-header-cell *matHeaderCellDef class="header-border-right">Name</th>
            <td mat-cell *matCellDef="let element" class="table-elem-border-right">{{ element.pipeline_name }}</td>
          </ng-container>

          <!-- Approval Status Column -->
          <ng-container matColumnDef="approval_status">
            <th mat-header-cell *matHeaderCellDef>
              <div class="d-flex align-items-center">
                Approval status
                <button
                  mat-icon-button
                  class="button-sm-size"
                  aria-label="Show Conect Status list info"
                  [matMenuTriggerFor]="menuInfo"
                  stopPropagationAction
                >
                  <mat-icon>info_outline</mat-icon>
                </button>
                <mat-menu #menuInfo="matMenu" class="statusInfo">
                  <app-approval-status-info></app-approval-status-info>
                </mat-menu>
              </div>
            </th>
            <td mat-cell *matCellDef="let element">
              <mat-chip-set
                aria-label="approval status"
                class="status-chip chip-size-m"
                [ngClass]="element.approval_status | statusColor"
              >
                <mat-chip>{{ element.approval_status }}</mat-chip>
              </mat-chip-set>
            </td>
          </ng-container>

          <!-- Connect Status Column -->
          <ng-container matColumnDef="connect_status">
            <th mat-header-cell *matHeaderCellDef>
              <div class="d-flex align-items-center">
                {{ statusColumnName }}
                <button
                  mat-icon-button
                  class="button-sm-size"
                  aria-label="Show Conect Status list info"
                  [matMenuTriggerFor]="menuInfo"
                  stopPropagationAction
                >
                  <mat-icon>info_outline</mat-icon>
                </button>
                <mat-menu #menuInfo="matMenu" class="statusInfo">
                  @if (isConnectors()) {
                    <app-connector-status-info></app-connector-status-info>
                  } @else {
                    <data-source-connect-status-info></data-source-connect-status-info>
                  }
                </mat-menu>
              </div>
            </th>
            <td mat-cell *matCellDef="let element">
              <mat-chip-set
                aria-label="connect status"
                class="status-chip chip-size-m"
                [ngClass]="
                  element.connect_status === 'Approved'
                    ? (element.connect_status + 'connector' | statusColor)
                    : (element.connect_status | statusColor)
                "
              >
                <mat-chip
                  [matTooltip]="errorStatusMessage(element)"
                  [matTooltipDisabled]="isTooltipDisabled(element.connect_status)"
                >
                  {{ element.connect_status }}
                </mat-chip>
              </mat-chip-set>
            </td>
          </ng-container>

          <!-- Pipeline Status Column -->
          <ng-container matColumnDef="pipeline_status">
            <th mat-header-cell *matHeaderCellDef>
              <div class="d-flex align-items-center">
                Status
                <button
                  mat-icon-button
                  class="button-sm-size"
                  aria-label="Show Conect Status list info"
                  [matMenuTriggerFor]="menuInfo"
                  stopPropagationAction
                >
                  <mat-icon>info_outline</mat-icon>
                </button>
                <mat-menu #menuInfo="matMenu" class="statusInfo">
                  <app-pipeline-status-info></app-pipeline-status-info>
                </mat-menu>
              </div>
            </th>
            <td mat-cell *matCellDef="let element">
              <mat-chip-set
                aria-label="pipeline_status"
                class="status-chip chip-size-m"
                [ngClass]="
                  element.status === 'Approved'
                    ? (element.status + 'pipeline' | statusColor)
                    : (element.status | statusColor)
                "
              >
                <mat-chip
                  [matTooltip]="errorStatusMessage(element)"
                  [matTooltipDisabled]="isTooltipDisabled(element.status)"
                >
                  {{ element.status }}
                </mat-chip>
              </mat-chip-set>
            </td>
          </ng-container>

          <!-- Last Run Connector Column -->
          <ng-container matColumnDef="last_connector_run_end">
            <th mat-header-cell *matHeaderCellDef>Last run date/time</th>
            <td mat-cell *matCellDef="let element">{{ element?.last_connector_run_end | dateFormat }}</td>
          </ng-container>

          <!-- Last Run Pipeline Column -->
          <ng-container matColumnDef="last_run_end">
            <th mat-header-cell *matHeaderCellDef>Last run date/time</th>
            <td mat-cell *matCellDef="let element">{{ element?.last_run_end | dateFormat }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="data.columns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: data.columns"></tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="6">
              @if (data.dataSource.length === 0) {
                <div class="empty-table">
                  <div class="empty-table-message-info">No data available</div>
                </div>
              } @else {
                <div class="mat-mdc-row mdc-data-table__cell flex-content-center">No data found</div>
              }
            </td>
          </tr>
        </table>
      </div>
      @if (data.url) {
        <button
          mat-button
          color="primary"
          type="button"
          class="action-button mt-2"
          (click)="onSeeMore(data.url)"
          data-test-id="seeMoreBtn"
        >
          See More
        </button>
      }
    </mat-card-content>
  </mat-card>
}
