import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { OrganizationItemDTO } from '@core/models';
import { OrganizationsService } from '@core/services';
import { catchError, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InternalUserOrganizationResolver implements Resolve<void> {
  private readonly PAGE_NOT_FOUND = ['page-not-found'];

  constructor(
    private router: Router,
    private readonly organizationsService: OrganizationsService,
  ) {}

  resolve(route: ActivatedRouteSnapshot): any {
    const organizationId = route.paramMap.get('organizationId')!;

    return this.organizationsService.getList().pipe(
      map((organizations) => {
        const organization = this.checkOrganizationExists(organizations, organizationId);

        if (!organization) {
          this.navigateToPageNotFound();
        }

        return organization;
      }),
      catchError(() => {
        this.navigateToPageNotFound();
        return of(undefined);
      }),
    );
  }

  private checkOrganizationExists(
    organizations: OrganizationItemDTO[],
    organizationId: string,
  ): OrganizationItemDTO | undefined {
    return organizations.find((organization) => organization.pk.toString() === organizationId);
  }

  private navigateToPageNotFound(): void {
    this.router.navigate(this.PAGE_NOT_FOUND, { skipLocationChange: true });
  }
}
