<div class="px-2">
  <div class="pb-2">
    <mat-chip-set aria-label="Status New" class="status-chip chip-size-m" [ngClass]="'New' | statusColor">
      <mat-chip>New</mat-chip>
    </mat-chip-set>
    <div>The issue has been reported and is pending review by Diliko.</div>
  </div>
  <div class="pb-2">
    <mat-chip-set aria-label="Status Open" class="status-chip chip-size-m" [ngClass]="'Open' | statusColor">
      <mat-chip>Open</mat-chip>
    </mat-chip-set>
    <div>Diliko is working on this issue.</div>
  </div>
  <div class="pb-2">
    <mat-chip-set aria-label="Status Pending" class="status-chip chip-size-m" [ngClass]="'Pending' | statusColor">
      <mat-chip>Pending</mat-chip>
    </mat-chip-set>
    <div>Issue needs information from customer users.</div>
  </div>
  <div class="pb-2">
    <mat-chip-set aria-label="Status Solved " class="status-chip chip-size-m" [ngClass]="'Solved' | statusColor">
      <mat-chip>Solved</mat-chip>
    </mat-chip-set>
    <div>Issue has been resolved. Please review.</div>
  </div>
</div>
