<main>
  <div class="pt-3 d-grid">
    @if (isLoading()) {
      <mat-progress-bar mode="query"></mat-progress-bar>
    }
    @if (organizationData$ | async; as organizationData) {
      @if (isDetailedView()) {
        <div class="row align-items-center">
          <div class="col title-section">
            <div class="d-flex">
              <span class="align-items-center organization-name">{{ organizationData.organization.name }}</span>
              @if (organizationData.organization.subscription_param_1.length) {
                @for (param of organizationData.organization.subscription_param_1; track param) {
                  <mat-chip class="ms-2 client-type-chip" [ngClass]="UniversalStatus | statusColor">
                    {{ param.name }}
                  </mat-chip>
                }
              }
            </div>
            @if (organizationData.organization.subscription_param_2.length) {
              <div class="d-flex customer-type">
                @for (param of organizationData.organization.subscription_param_2; let last = $last; track param) {
                  <b>{{ param.name }}{{ last ? '' : ',' }}&nbsp;</b>
                }
              </div>
            }
          </div>
          <div class="col d-grid actions-section">
            <!-- todo: waiting for requirements -->
            <!-- <button mat-stroked-button color="primary" disabled="true" matTooltip="to be implemented">
              Add as favorites
            </button> -->
            <button mat-flat-button color="primary" [routerLink]="['/organizations', selectedOrganization(), 'home']">
              View Details
            </button>
          </div>
        </div>
      }
      <div class="row my-2">
        @for (statistic of statistics(); track statistics.name) {
          <app-statistics-tile-organizations
            *accessView="[statistic.permission]"
            class="statisctic-tile"
            [statistic]="statistic"
          ></app-statistics-tile-organizations>
        }
      </div>
      @if (isDetailedView()) {
        <div class="row my-2 g-2 d-grid products-section">
          @for (product of products(); track product) {
            <app-product-tile-organizations class="col" [product]="product"></app-product-tile-organizations>
          }
        </div>
      }
      <div class="tables-section pt-3">
        <app-status-table-organizations
          class="table-widget"
          *accessView="['view_datasource']"
          [data]="dataSources()"
        ></app-status-table-organizations>
        <app-status-table-organizations
          class="table-widget"
          *accessView="['view_connector']"
          [data]="connectors()"
        ></app-status-table-organizations>
        <app-status-table-organizations
          class="table-widget"
          *accessView="['view_pipeline']"
          [data]="pipelines()"
        ></app-status-table-organizations>
        <app-zendesk-tickets-table
          *accessView="['view_zendeskticket']"
          [organizationId]="selectedOrganization()"
          class="table-widget"
        ></app-zendesk-tickets-table>
      </div>
    }
  </div>
</main>
