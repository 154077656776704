import { Component, input } from '@angular/core';
import { Router } from '@angular/router';
import {
  ConnectorsStatusesType,
  DataSourcesStatusesType,
  ERROR_TOOLTIP_STATUSES,
  OrganizationConnectorItemDTO,
  OrganizationDataSourceItemDTO,
  OrganizationPipelineItemDTO,
  PipelinesStatusesType,
} from '@core/models';
import { OrganizationTableDataDTO } from '@features/organizations/models';

@Component({
  selector: 'app-status-table-organizations',
  templateUrl: './status-table-organizations.component.html',
  styleUrl: './status-table-organizations.component.scss',
  standalone: false,
})
export class StatusTableOrganizationsComponent {
  errorStatuses!: (ConnectorsStatusesType | DataSourcesStatusesType | PipelinesStatusesType)[];

  public data = input.required<OrganizationTableDataDTO, OrganizationTableDataDTO>({
    transform: (value: OrganizationTableDataDTO) => {
      switch (value.title) {
        case 'Connectors':
          this.errorStatuses = ERROR_TOOLTIP_STATUSES.Connectors;
          break;
        case 'Pipelines':
          this.errorStatuses = ERROR_TOOLTIP_STATUSES.Pipelines;
          break;
        case 'Data Sources':
          this.errorStatuses = ERROR_TOOLTIP_STATUSES.DataSources;
          break;
      }

      return value;
    },
  });

  constructor(private router: Router) {}

  get statusColumnName(): string {
    return this.isConnectors() ? 'Status' : 'Connect status';
  }

  errorStatusMessage(
    element: OrganizationConnectorItemDTO | OrganizationDataSourceItemDTO | OrganizationPipelineItemDTO,
  ): string {
    switch (this.data().title) {
      case 'Connectors':
        return (element as OrganizationConnectorItemDTO).last_connector_run_error_message;

      case 'Pipelines':
        return (element as OrganizationPipelineItemDTO).error_message;

      case 'DataSources':
      default:
        return (element as OrganizationDataSourceItemDTO).last_discovery_run_error_message;
    }
  }

  isTooltipDisabled(status: string): boolean {
    const statusLocal = status.toLowerCase() as
      | ConnectorsStatusesType
      | DataSourcesStatusesType
      | PipelinesStatusesType;
    return !this.errorStatuses.includes(statusLocal);
  }

  isConnectors(): boolean {
    return this.data().title === 'Connectors';
  }

  onSeeMore(url: string) {
    this.router.navigateByUrl(url);
  }
}
