<app-user-mimic-status-bar></app-user-mimic-status-bar>
<app-header></app-header>
<mat-drawer-container class="content-container">
  <mat-drawer mode="side" opened [disableClose]="true">
    <div class="drawer-content">
      <app-menu></app-menu>
      <app-support-links-panel></app-support-links-panel>
    </div>
  </mat-drawer>
  <mat-drawer-content>
    <main>
      <router-outlet></router-outlet>
    </main>
  </mat-drawer-content>
</mat-drawer-container>
