import { Component } from '@angular/core';
import { environment } from '@environments/environment';
import { AccountService, AuthenticationService } from '@features/server-side-authentication';

@Component({
  selector: 'app-page-no-role',
  templateUrl: './page-no-role.component.html',
  styleUrl: './page-no-role.component.scss',
  standalone: false,
})
export class PageNoRoleComponent {
  main_site_url = environment.postLogoutUri;
  constructor(
    private readonly accountService: AccountService,
    private readonly authenticationService: AuthenticationService,
  ) {}

  get mailToSupport() {
    return `mailto:${environment.feedbackEmail}`;
  }

  ngOnInit() {
    this.accountService.clear();
    this.authenticationService.logout().subscribe();
  }
}
