<div class="message-container">
  <error-page-content>
    <div style="width: 504px">
      <h1 class="error-main-text text-start">Role not assigned</h1>
      <p class="text-start">
        You have signed in to Diliko using Single Sign-On (SSO); however, it appears that your account does not have a
        role assigned.
        <br />
        <br />
        To obtain the necessary role, please contact your Diliko administrator within your organization.
        <br />
        <br />
        If you believe this is an error or require further assistance, please reach out to our
        <a class="diliko-support-link" [href]="mailToSupport">Diliko support team.</a>
      </p>
    </div>
    <div class="row">
      <div class="col-12 pt-4">
        <a mat-flat-button color="primary" class="error-nav-button" [href]="main_site_url">Corporate Site</a>
      </div>
    </div>
  </error-page-content>
</div>
